$theme: ekonomi;
@use "design-system" as ds;

.map {
  @include ds.stack();
  @include ds.pad-lr(medium);
}

.mapImg {
  & img {
    width: 100%;
    height: auto;
    display: block;
  }
}
