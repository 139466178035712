$theme: ekonomi;
@use "design-system" as ds;

.clusterContainer {
  padding-bottom: ds.spacing();
  @include ds.card($theme: $theme, $collapsing: true);

  &.articlePageTeaser {
    @include ds.background(card--subtle, $theme: $theme);

    &.native {
      @include ds.background(surface-primary, $namespace: ads, $theme: $theme);
    }
  }

  &.native {
    @include ds.background(surface-primary, $namespace: ads, $theme: $theme);
  }

  &.premium &:not(.smallCluster) {
    padding-top: ds.spacing(regular-increased);
    @include ds.card($theme: $theme);
  }

  &.noCardOutline {
    border: 0;
  }
}
