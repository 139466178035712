$theme: ekonomi;
@use "design-system" as ds;

.sponsoredBanner {
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: sticky;
  @include ds.background(surface-primary, $namespace: ads, $theme: $theme);
  padding: ds.spacing(micro) ds.spacing();
  z-index: ds.$z-index--floating;
  @include ds.border(divider--subtle, $a: 1px, $theme: $theme);
  border-radius: ds.$border-radius--default;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: margin 0.25s ease-out;

  // -1px hides top border
  top: calc(ds.$header-height--smallscreen - 1px);

  @media (min-width: #{ds.$screen-size--small}) {
    height: 32px;
    padding: ds.spacing(micro) ds.spacing();
    top: calc(ds.$header-height--largescreen - 1px);
  }
}

.margin {
  margin: 0 ds.spacing(base) ds.spacing(base) ds.spacing(base);

  @media (min-width: #{ds.$screen-size--small}) {
    margin: 0 0 ds.spacing(base) 0;
  }
}

.floating {
  // -1px hides top border
  top: calc(ds.$header-height--smallscreen - 1px);
  margin: 0 0 ds.spacing(base) 0; // Keep margin bottom to avoid jumping
  @include ds.border(divider--subtle, $b: 1px, $theme: $theme);

  @media (min-width: #{ds.$screen-size--small}) {
    @include ds.border(divider--subtle, $a: 1px, $theme: $theme);
    top: calc(ds.$header-height--largescreen - 1px);
    margin-left: ds.spacing();
    margin-right: ds.spacing();
    border-radius: ds.$border-radius--default;
  }
}
