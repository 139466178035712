$theme: ekonomi;
@use "design-system" as ds;

.footerContainer {
  display: flex;
  justify-content: center;
  margin-top: ds.spacing();
  padding-top: ds.spacing(base);
  @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
  margin-bottom: -8px;

  &.premium {
    padding: ds.spacing() 0 ds.spacing(base) 0;
  }

  &.native {
    @include ds.divider($theme: $theme);
    margin-top: ds.spacing();
    @include ds.stack();
    @include ds.pad-lr();

    &.button {
      margin: 0 auto;
    }
  }

  &.noBorder {
    border: 0;
  }
}
