$theme: ekonomi;
@use "sass:math";
@use "design-system" as ds;
$theme: core !default;

.discoverCategories {
  @include ds.card($theme: $theme, $collapsing: true);
  overflow: hidden;
  padding: ds.spacing(base) 0;
}

.discoverCategoriesTitle {
  @include ds.typography(h2, $theme: $theme);
  padding: ds.spacing();
}

.itemGroup {
  @media (min-width: ds.$screen-size--medium) {
    margin-bottom: ds.spacing(micro);
    padding-bottom: ds.spacing(medium);
    @include ds.border(divider--subtle, $b: 1px, $theme: $theme);
    &:last-child {
      border-bottom: 0;
    }
  }
}

.itemGroupTitle {
  padding: ds.spacing() ds.spacing(regular-increased);
  @include ds.typography(h3, $theme);
  @include ds.text-color(primary, $theme: $theme);
  align-items: center;
  display: flex;
  flex-direction: row;
}

.itemText {
  flex-grow: 1;
}

.itemGroupItems {
  @media (min-width: ds.$screen-size--medium) {
    columns: 2;
    gap: ds.spacing(base);
  }
}

.item {
  @include ds.background(primary, $theme: $theme);
  @include ds.border(divider--subtlest, $t: 1px, $theme: $theme);
  padding: ds.spacing() ds.spacing(regular-increased);
  @include ds.typography(h7, $theme, $responsive: false);
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ds.spacing(base);
  cursor: pointer;
  @include ds.text-color(primary, $theme: $theme);
}

.itemText {
  flex-grow: 1;
}

.itemNav {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ds.spacing(base);
  color: ds.color(ui, divider--subtle, $theme: $theme);
}
