$theme: ekonomi;
@use "design-system" as ds;

.containerList {
  @include ds.card($theme: $theme, $collapsing: true);
  padding-top: ds.spacing();
  padding-bottom: ds.spacing();
}

.tabs {
  @include ds.pad();
  padding-top: ds.spacing(micro);
  @include ds.border(divider--subtle, $b: 1px, $theme: $theme);
}

.topicsContainer {
  margin-top: ds.spacing();
}

.loadMoreButtonContainer {
  @include ds.divider($theme: $theme);
  margin-top: ds.spacing();
  @include ds.stack();
  @include ds.pad-lr();

  button {
    margin: 0 auto;
  }
}
