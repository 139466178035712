$theme: ekonomi;
@use "design-system" as ds;

$small-teaser-width: 100px;

.teaserContainer {
  & + .teaserContainer {
    margin-top: ds.spacing();

    &:before {
      @include ds.divider($theme);
      content: "";
      margin-bottom: ds.spacing();
      margin-left: 16px;
      margin-right: 16px;
      display: block;
    }
  }
}

.teaser {
  display: block;

  &:hover,
  &:visited:hover {
    h2,
    p {
      @include ds.text-color(primary--prominent, $theme: $theme);
    }
  }

  &.large {
    margin-top: ds.spacing(micro);
  }
}

.teaserContent {
  display: flex;
  flex-direction: row;
  gap: ds.spacing("regular-increased");
  margin: 0 ds.spacing();
}

.teaserTextContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ds.spacing("base");
  word-break: break-word;
}
