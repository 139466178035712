$theme: ekonomi;
@use "design-system" as ds;

.videoThumbnail {
  padding: 0;
  margin-bottom: ds.spacing();
}

.map {
  padding: 0 0 ds.spacing() 0;
}
